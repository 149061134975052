import React, { FC, useContext } from 'react';
import { useWatch } from 'react-hook-form';
import {
    Datagrid,
    DateField,
    DateInput,
    Edit,
    ReferenceManyField,
    required,
    SelectField,
    SelectInput,
    SimpleForm,
    SimpleShowLayout,
    TextField,
    TextInput,
    useRecordContext,
    useListContext,
    EditContext,
    FunctionField,
    RecordContext,
    CheckboxGroupInput,
    useTranslate,
    BooleanInput,
    FormDataConsumer,
    Create,
    Labeled,
    SelectArrayInput,
    ReferenceInput,
    AutocompleteInput,
    ArrayInput,
    SimpleFormIterator,
} from 'react-admin';
import {
    CreateInDialogButton,
    EditInDialogButton,
    ShowInDialogButton,
} from '@react-admin/ra-form-layout';
import { Grid, Box, Typography, Stack, Alert } from '@mui/material';
import { ValidateEmailUnicity } from './teacher/TeacherEdit';
import { add, chain } from 'lodash';
import school from '../school';
import {
    CustomAutocompleteInput,
    CustomCheckboxGroupInput,
    CustomRadioButtonGroupInput,
    CustomSelectArrayInput,
    CustomSelectInput,
} from '../controls';
import customFinnishMessages from '../../i18n/fi';

const Schools: FC<any> = props => {
    if (!(props?.schools?.length || 0)) {
        return null;
    }

    console.log('schools', props.schools);
    return (
        <Stack spacing={2}>
            {props.schools.map((school, i) => (
                <Stack spacing={0} direction="column" key={i}>
                    <Labeled label="resources.teacher.fields.school.title">
                        <>
                            <TextField
                                source={`schools[${i}].name`}
                                defaultValue={school.name}
                            />

                            <BooleanInput
                                label={school.name}
                                source={`schools[${i}].add`}
                                sx={{ width: '0px', display: 'none' }}
                            />
                            <TextInput
                                source={`schools[${i}].guid`}
                                sx={{ width: '0px', display: 'none' }}
                                defaultValue={school.guid}
                            />
                        </>
                    </Labeled>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => {
                            return formData?.schools?.[i]?.add ? (
                                <Stack spacing={5} direction="column">
                                    <Labeled label="resources.teacher.fields.school.settings">
                                        <Stack spacing={2} direction="column">
                                            <Stack spacing={2} direction="row">
                                                <BooleanInput
                                                    label="Vapaalla"
                                                    source={`schools[${i}].ofduty`}
                                                />
                                                <BooleanInput
                                                    label="Ylläpitäjä"
                                                    source={`schools[${i}].isadmin`}
                                                />

                                                <CustomSelectInput
                                                    resource="teacher"
                                                    label="resources.teacher.fields.role.guid"
                                                    source={`schools[${i}].role.guid`}
                                                    validate={required()}
                                                    url="extralist/teacher/roles"
                                                />

                                                <FormDataConsumer>
                                                    {({
                                                        formData,
                                                        ...rest
                                                    }) => {
                                                        //console.log('formData', formData);
                                                        return formData
                                                            ?.schools?.[i]?.role
                                                            ?.guid ? (
                                                            <CustomSelectArrayInput
                                                                label="resources.teacher.fields.role.subjects"
                                                                resource="teacher"
                                                                source={`schools[${i}].role.subjects`}
                                                                optionText="name"
                                                                optionValue="guid"
                                                                url={`extralist/teacher/role/${formData?.schools?.[i]?.role?.guid}/subjects`}
                                                                {...rest}
                                                            />
                                                        ) : null;
                                                    }}
                                                </FormDataConsumer>
                                            </Stack>
                                        </Stack>
                                    </Labeled>
                                    {(school?.groups?.length || 0 > 1) && (
                                        <Labeled label="resources.teacher.fields.school.group">
                                            <Stack
                                                spacing={0}
                                                direction="column"
                                                sx={{ marginLeft: '1rem' }}
                                            >
                                                {formData.status === 0 &&
                                                    school?.groups?.map(
                                                        (group, j) => (
                                                            <Stack
                                                                spacing={0}
                                                                direction="column"
                                                                key={`schools_${i}.groups_${j}`}
                                                            >
                                                                <BooleanInput
                                                                    label={
                                                                        group.name
                                                                    }
                                                                    source={`schools[${i}].groups[${j}].add`}
                                                                />
                                                                <TextInput
                                                                    source={`schools[${i}].groups[${j}].guid`}
                                                                    sx={{
                                                                        width: '0px',
                                                                        display:
                                                                            'none',
                                                                    }}
                                                                    defaultValue={
                                                                        group.guid
                                                                    }
                                                                />
                                                            </Stack>
                                                        )
                                                    )}
                                            </Stack>
                                        </Labeled>
                                    )}
                                </Stack>
                            ) : null;
                        }}
                    </FormDataConsumer>

                    {/* Add any additional fields or adjustments you may need here */}
                </Stack>
            ))}
        </Stack>
    );
};

const PupilSchools: FC<any> = props => {
    console.log('props', props);
    return (
        <ArrayInput source="schools">
            <SimpleFormIterator disableReordering>
                <Stack
                    spacing={0}
                    direction="column"
                    sx={{
                        border: '1px solid #ccc',
                        padding: '1rem',
                        borderRadius: '5px',
                        marginTop: '1rem',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <ReferenceInput source="guid" reference="extralist/schools">
                        <AutocompleteInput
                            source="guid"
                            optionText="item_title"
                            optionValue="item_id"
                            label="Koulu"
                            validate={required()}
                            shouldRenderSuggestions={val => {
                                return val.trim().length > 2;
                            }}
                        />
                    </ReferenceInput>

                    <FormDataConsumer>
                        {({ formData, scopedFormData }) => {
                            //console.log('formData', formData);
                            return scopedFormData && scopedFormData?.guid ? (
                                <CustomSelectArrayInput
                                    label="resources.pupil.fields.groups"
                                    source="groups"
                                    optionText="item_title"
                                    optionValue="item_id"
                                    url={`extralist/school/${scopedFormData?.guid}/groups?filter={"status":0,"archived":false,"limit":500}`}
                                />
                            ) : null;
                        }}
                    </FormDataConsumer>
                </Stack>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const TeacherSchools: FC<any> = props => {
    console.log('props', props);
    return (
        <ArrayInput source="schools">
            <SimpleFormIterator disableReordering>
                <Stack
                    spacing={0}
                    direction="column"
                    sx={{
                        border: '1px solid #ccc',
                        padding: '1rem',
                        borderRadius: '5px',
                        marginTop: '1rem',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <Stack spacing={2} direction="row">
                        <ReferenceInput
                            source="guid"
                            reference="extralist/schools"
                        >
                            <AutocompleteInput
                                source="guid"
                                optionText="item_title"
                                optionValue="item_id"
                                label="Koulu"
                                validate={required()}
                                shouldRenderSuggestions={val => {
                                    return val.trim().length > 2;
                                }}
                            />
                        </ReferenceInput>

                        <BooleanInput source="public.ofduty" label="Vapaalla" />
                        <BooleanInput
                            source="public.isadmin"
                            label="Ylläpitäjä"
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <CustomSelectInput
                            resource="teacher"
                            label="resources.teacher.fields.role.guid"
                            source={`role_guid`}
                            validate={required()}
                            url="extralist/teacher/roles"
                        />

                        <FormDataConsumer>
                            {({ formData, scopedFormData }) => {
                                //console.log('formData', formData);
                                return scopedFormData &&
                                    scopedFormData?.role_guid ? (
                                    <CustomSelectArrayInput
                                        label="resources.teacher.fields.role.subjects"
                                        resource="teacher"
                                        source={`public.subject`}
                                        optionText="name"
                                        optionValue="guid"
                                        url={`extralist/teacher/role/${scopedFormData?.role_guid}/subjects`}
                                    />
                                ) : null;
                            }}
                        </FormDataConsumer>
                    </Stack>
                </Stack>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const Groups: FC<any> = props => {
    if (!(props.data?.groups?.length || 0)) {
        return null;
    }

    const choices = props.data.groups.map(group => ({
        id: group.id,
        name: group.name,
    }));

    return <CheckboxGroupInput source="groups" choices={choices} />;
};

export const MailExample: FC<any> = ({
    lang,
    application,
    status,
    mailtemplate,
}) => {
    const translate = useTranslate();
    const [template, setMailTemplate] = React.useState<any>([]);

    React.useEffect(() => {
        const fetchMailTemplate = async () => {
            try {
                const filter = JSON.stringify({
                    lang: lang,
                    template: mailtemplate || `TEACHER_STATUS_${status}`,
                    application: application,
                });
                const encodedFilter = encodeURIComponent(filter);
                const api = `${process.env.REACT_APP_INFRA_API}/emailtemplates?filter=${encodedFilter}`;
                const token = localStorage.getItem('token');

                const response = await fetch(api, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (!response.ok) {
                    throw new Error(
                        `Failed to fetch mail template: ${response.status}`
                    );
                }
                const data = await response.json();
                setMailTemplate(data);
            } catch (error) {
                console.error('Error fetching mail template:', error);
                setMailTemplate([]);
            }
        };

        fetchMailTemplate();
    }, [lang, application, status]);

    return (
        <Stack
            spacing={2}
            direction="column"
            width={{ xs: '100%', sm: '100%' }}
        >
            <Labeled label="resources.teacher.actions.new.messagepreview">
                <Box
                    sx={{
                        width: '100%',
                        border: '1px solid #ccc',
                        padding: '1rem',
                        borderRadius: '5px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {template?.[0]?.body ? (
                        <>
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: '0.8rem',
                                    color: 'rgba(0, 0, 0, 0.54)',
                                    whiteSpace: 'pre-line',
                                }}
                            >
                                {`${template?.[0]?.from}\n`}
                                {`${template?.[0]?.subject}\n\n`}
                                {`${template?.[0]?.body}\n`}
                            </Typography>
                            {template?.[0]?.active === false && (
                                <Alert
                                    severity="warning"
                                    sx={{ marginTop: '1rem' }}
                                >
                                    {translate(
                                        'resources.teacher.actions.new.mailtemplateinactive'
                                    )}
                                </Alert>
                            )}
                        </>
                    ) : (
                        <Alert severity="warning" sx={{ padding: 10 }}>
                            {translate(
                                'resources.teacher.actions.new.nomailtemplate'
                            )}
                        </Alert>
                    )}
                </Box>
            </Labeled>
        </Stack>
    );
};

const languages = [
    { id: 'fi', name: 'Suomi' },
    { id: 'sv', name: 'Ruotsi' },
    { id: 'en', name: 'Englanti' },
];

export const SendMailLang: FC<any> = props => {
    return (
        <SelectInput
            source={props.source || 'lang'}
            choices={languages}
            defaultValue={'fi'}
            required
            label="resources.teacher.actions.new.lang"
            sx={{ width: 'fit-content' }}
        />
    );
};

const applicationConfig = {
    teacher: [
        {
            id: '0e274549-7a89-4313-8720-0f4588a51f98',
            name: 'Opepalvelu',
        },
        {
            id: '7f7421b3-0aa9-40ce-82d0-f76dc5660a37',
            name: 'Nova',
        },
        {
            id: 'b4319206-be13-430e-a7fb-131c92e1e903',
            name: 'Finnlectura',
        },
    ],
    student: [
        {
            id: 'a7faf0f2-3cfc-4f62-9a77-aa75134ac5a4',
            name: 'Opiskelija',
        },
        {
            id: 'e0e0e231-be98-43ae-bfb6-3e136bf17f43',
            name: 'Nova',
        },
        {
            id: '1b2297bd-c587-47aa-aec8-13460f0edbf2',
            name: 'Finnlectura',
        },
    ],
    pupil: [
        {
            id: '5b846602-4428-4c2d-b12a-d2b56dd722b7',
            name: 'Nova',
        },
    ],
};

const applications = (resource: 'teacher' | 'student' | 'pupil') => {
    return applicationConfig[resource] || [];
};

export const SendMailApplication: FC<any> = props => {
    const { resource } = props;
    console.log('props', props);
    return (
        <SelectInput
            source={props.source || 'application'}
            choices={applications(resource)}
            required
            label="resources.teacher.actions.new.application"
            sx={{ width: 'fit-content' }}
        />
    );
};

const Options: FC<any> = () => {
    const translate = useTranslate();

    return (
        <CheckboxGroupInput
            source="options"
            choices={[
                {
                    id: 'sendmail',
                    name: translate('resources.teacher.actions.new.sendmail'),
                },
            ]}
        />
    );
};

const NewTeacherForm = (props: any) => {
    const translate = useTranslate();
    return (
        <SimpleForm
            defaultValues={{
                sendmail: false,
                lang: 'fi',
                status: 0,
                schools: [
                    {
                        ofduty: false,
                        isadmin: false,
                    },
                ],
            }}
            {...props}
        >
            <Stack
                spacing={2}
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ width: '100%' }}
            >
                <Stack spacing={2} width={{ xs: '100%', sm: '100%' }}>
                    <Stack spacing={2} direction="row">
                        <TextInput
                            source="public.firstname"
                            validate={required()}
                        />
                        <TextInput
                            source="public.lastname"
                            validate={required()}
                        />
                    </Stack>
                    <Stack spacing={2} direction="row">
                        <TextInput
                            source="username"
                            validate={[
                                required(),
                                (value: string) =>
                                    ValidateEmailUnicity(value, false, ''),
                            ]}
                        />

                        <CustomSelectInput
                            resource="teacher"
                            source="status"
                            validate={required()}
                            url="extralist/teacher/statuses"
                        />
                    </Stack>

                    <TeacherSchools {...props} />
                    <Box
                        sx={{
                            width: '100%',
                            height: '1px',
                            backgroundColor: 'grey.300',
                            margin: '16px 0',
                        }}
                    />

                    <Stack
                        spacing={2}
                        direction="row"
                        sx={{ paddingTop: '1rem' }}
                    >
                        <Stack
                            spacing={0}
                            direction="column"
                            sx={{ width: '30%' }}
                        >
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <BooleanInput
                                        source="sendmail"
                                        label={translate(
                                            'resources.teacher.actions.new.sendmail'
                                        )}
                                        sx={{ whiteSpace: 'nowrap' }}
                                        disabled={
                                            !(
                                                formData.status === 0 ||
                                                formData.status === 3
                                            )
                                        }
                                    />
                                )}
                            </FormDataConsumer>

                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    formData.sendmail ? (
                                        <>
                                            <SendMailLang
                                                resource="teacher"
                                                {...rest}
                                            />
                                            <SendMailApplication
                                                resource="teacher"
                                                {...rest}
                                            />
                                        </>
                                    ) : null
                                }
                            </FormDataConsumer>
                        </Stack>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                formData.lang &&
                                formData.sendmail &&
                                formData.application ? (
                                    <MailExample
                                        lang={formData.lang}
                                        application={formData.application || ''}
                                        status={formData.status}
                                        {...rest}
                                    />
                                ) : null
                            }
                        </FormDataConsumer>
                    </Stack>
                </Stack>
            </Stack>
        </SimpleForm>
    );
};

const AddTeacherForm = (props: any) => (
    <SimpleForm defaultValues={{ action: 'ADD_NEW_TEACHER' }} {...props}>
        <TextInput
            source="email"
            validate={(value: string) => ValidateEmailUnicity(value, true)}
        />
        <Schools />
        <Groups />
        <Options />
    </SimpleForm>
);

const RandomCode = () => {
    // Use type-safe fallback for customFinnishMessages
    const codes = (customFinnishMessages as any)?.resources?.pupil?.codes || [
        'punainen',
        'kesä',
        'talvi',
        'syksy',
        'kevät',
    ];
    const randomIndex = Math.floor(Math.random() * codes.length);
    return (
        codes[randomIndex] +
        String(Math.floor(Math.random() * 10000)).padStart(4, '0')
    );
};

const NewPupilForm = (props: any) => {
    const translate = useTranslate();

    return (
        <SimpleForm
            defaultValues={{
                status: 0,
                public: {
                    code: RandomCode(),
                },
                schools: [{}],
            }}
            {...props}
        >
            <Stack
                spacing={2}
                direction={{ xs: 'column', sm: 'row' }}
                sx={{ width: '100%' }}
            >
                <Stack spacing={2} width={{ xs: '100%', sm: '100%' }}>
                    <Stack spacing={2} direction="row">
                        <TextInput
                            source="public.firstname"
                            validate={required()}
                        />
                        <TextInput
                            source="public.lastname"
                            validate={required()}
                        />
                        <TextInput source="public.code" validate={required()} />
                    </Stack>
                    <PupilSchools {...props} />
                </Stack>
            </Stack>
        </SimpleForm>
    );
};

const NewStudentForm = (props: any) => (
    <SimpleForm defaultValues={{ firstname: 'John', name: 'Doe' }} {...props}>
        <TextInput source="first_name" validate={required()} />
        <TextInput source="last_name" validate={required()} />
        <DateInput source="dob" label="born" validate={required()} />
    </SimpleForm>
);

const NewUserForm = (props: any) => (
    <SimpleForm defaultValues={{ firstname: 'John', name: 'Doe' }} {...props}>
        <TextInput source="first_name" validate={required()} />
        <TextInput source="last_name" validate={required()} />
        <DateInput source="dob" label="born" validate={required()} />
    </SimpleForm>
);

interface CreateUserDialogButtonProps {
    resource: 'student' | 'pupil' | 'teacher' | 'user';
}

const useOptionalEditContext = () => {
    const context = useContext(EditContext);
    return context || { record: {} };
};

const CreateUserDialogButton = ({ resource }: CreateUserDialogButtonProps) => {
    console.log('resource', resource);
    const { record } = useOptionalEditContext();
    //console.log('record', record);

    const buildRecord = (record: any) => {
        if (!record)
            return {
                schools: [],
            };
        if (record.type === 2) {
            return {
                schools: [{ guid: record.id, name: record.name, groups: [] }],
            };
        } else if (record.type === 4) {
            return {
                schools: [
                    {
                        guid: record.parent_group?.[0]?.guid,
                        name: record.parent_group?.[0]?.name,
                        groups: [{ guid: record.id, name: record.name }],
                    },
                ],
            };
        }
        return record;
    };

    return (
        <>
            <CreateInDialogButton
                fullWidth
                maxWidth="md"
                resource={resource}
                label={`resources.${resource}.actions.new.title`}
            >
                {(() => {
                    switch (resource) {
                        case 'teacher':
                            return (
                                <NewTeacherForm
                                    resource={resource}
                                    record={buildRecord(record)}
                                />
                            );
                        case 'student':
                            return (
                                <NewStudentForm
                                    resource={resource}
                                    record={buildRecord(record)}
                                />
                            );
                        case 'pupil':
                            return (
                                <NewPupilForm
                                    resource={resource}
                                    record={buildRecord(record)}
                                />
                            );
                        case 'user':
                            return (
                                <NewUserForm
                                    resource={resource}
                                    record={buildRecord(record)}
                                />
                            );
                        default:
                            return null;
                    }
                })()}
            </CreateInDialogButton>
        </>
    );
};

export default CreateUserDialogButton;
