import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from '@mui/material';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green, red } from '@mui/material/colors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import {
    useTranslate,
    useRecordContext,
    useNotify,
    useRefresh,
    useResourceContext,
} from 'react-admin';

const ChangeUserStatus = ({ ...props }) => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const handleRefresh = () => {
        refresh();
    };
    const record = useRecordContext();
    const resource = useResourceContext(props);
    const [processingButtons, setProcessingButtons] = useState({});
    const [open, setOpen] = useState(false);
    const [sendmail, setSendMail] = useState(true);
    const [remove_user, setRemoveUser] = useState(false);
    const [language, setLanguage] = useState('fi');
    const [shouldTriggerAction, setShouldTriggerAction] = useState(false);
    const [remove_groups, setRemoveGroups] = useState(true);

    useEffect(() => {
        if (shouldTriggerAction) {
            handleAction(new Event('click'), record.guid);
            setShouldTriggerAction(false);
        }
    }, [shouldTriggerAction]);

    const {
        button: showButton = true,
        action = 'approve',
        school_guid,
    } = props;

    const [checkedState, setCheckedState] = useState(
        record?.schools
            ?.filter(s => s.status !== 1)
            .reduce((acc, school) => {
                acc[school.guid] = school_guid
                    ? school.guid === school_guid
                    : true; // Default to checked if it matches school_guid or school_guid is undefined
                return acc;
            }, {})
    );

    const handleLanguageChange = event => {
        setLanguage(event.target.value);
    };

    const handleCheckboxChange = event => {
        const { name, checked } = event.target;
        setCheckedState(prevState => ({
            ...prevState,
            [name]: checked,
        }));
    };

    const handleSendMailCheckboxChange = event => {
        const { checked } = event.target;
        setSendMail(checked);
        //console.log('Send mail checked:', checked);
    };

    const handleRemoveTeacherCheckboxChange = event => {
        const { checked } = event.target;
        setRemoveUser(checked);
        //console.log('Remove teacher checked:', checked);
    };

    const handleRemoveGroupsCheckboxChange = event => {
        const { checked } = event.target;
        setRemoveGroups(checked);
        //console.log('Remove groups checked:', checked);
    };

    const handleAction = (e, guid) => {
        e.stopPropagation();
        setProcessingButtons(prevState => ({ ...prevState, [guid]: true }));

        //Find the checked schools
        let checkedSchools = [];
        for (const [key, value] of Object.entries(checkedState)) {
            if (value) {
                checkedSchools.push(key);
            }
        }

        //Call api to approve user and assign schools
        let data;
        switch (action) {
            case 'approve':
                data = {
                    action: `APPROVE_${resource.toUpperCase()}`,
                    user_guid: guid,
                    groups_to_approve: checkedSchools,
                    approve_user: true,
                    sendmail: sendmail,
                    lang: language,
                };
                break;
            case 'remove':
                data = {
                    action: `REMOVE_${resource.toUpperCase()}`,
                    user_guid: guid,
                    remove_user: remove_user,
                    groups_to_remove: checkedSchools,
                    remove_groups: remove_groups,
                };
                break;
            case 'unremove':
                data = {
                    action: `APPROVE_${resource.toUpperCase()}`,
                    user_guid: guid,
                    groups_to_approve: checkedSchools,
                    approve_user: false,
                    sendmail: false,
                    lang: language,
                };
                break;
            default:
                break;
        }
        console.log('Data to be sent:', JSON.stringify(data));
        console.log('Record:', JSON.stringify(resource));

        if (!data) {
            notify(translate(`resources.${resource}.actions.approve.error`), {
                type: 'error',
            });
            setProcessingButtons(prevState => ({
                ...prevState,
                [guid]: false,
            }));
            return;
        }
        // Send the request to the API
        fetch(`${process.env.REACT_APP_INFRA_API}/${resource}/${guid}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(data),
        })
            .then(response => {
                if (response.status !== 200) {
                    throw new Error(response.statusText);
                }
                notify(
                    translate(`resources.${resource}.actions.approve.success`),
                    {
                        type: 'success',
                    }
                );
                handleRefresh();
                setProcessingButtons(prevState => ({
                    ...prevState,
                    [guid]: false,
                }));
            })
            .catch(() => {
                notify(
                    translate(`resources.${resource}.actions.approve.error`),
                    {
                        type: 'error',
                    }
                );
                setProcessingButtons(prevState => ({
                    ...prevState,
                    [guid]: false,
                }));
            });
    };

    const handleOpenDialog = e => {
        e.stopPropagation();
        setOpen(true);
    };

    const handleCloseDialog = e => {
        e.stopPropagation();
        setOpen(false);
    };

    const handleContinue = e => {
        handleAction(e, record.guid);
        handleCloseDialog(e);
    };

    if (!record) {
        return null;
    }

    return (
        <>
            {showButton && (
                <Button
                    key={`approve_${record.guid}`}
                    variant="contained"
                    size="small"
                    color="error"
                    title={props.title}
                    startIcon={
                        processingButtons[record.guid] ? (
                            <CircularProgress size={14} />
                        ) : null
                    }
                    onClick={handleOpenDialog}
                    disabled={processingButtons[record.guid]}
                >
                    {props.title ||
                        translate(
                            `resources.${resource}.actions.approve.button_title`
                        )}
                </Button>
            )}
            {!showButton &&
                action === 'approve' &&
                (record.status === 2 ||
                    (record?.schools?.filter(s => s.status === 2)?.length ??
                        0) > 0) && (
                    <IconButton
                        key={`approve_${record.guid}`}
                        size="small"
                        title={
                            props.title ||
                            translate(
                                `resources.${resource}.actions.approve.button_title`
                            )
                        }
                        onClick={handleOpenDialog}
                    >
                        <CheckCircleIcon color="error" fontSize="inherit" />
                    </IconButton>
                )}
            {!showButton &&
                action === 'remove' &&
                record.status !== 1 &&
                (record?.schools?.filter(s => s.status !== 1)?.length ?? 0) >
                    0 && (
                    <IconButton
                        key={`remove_${record.guid}`}
                        size="small"
                        title={
                            props.title ||
                            translate(
                                `resources.${resource}.actions.remove.button_title`
                            )
                        }
                        onClick={handleOpenDialog}
                    >
                        <RemoveCircleOutlineIcon
                            sx={{ color: red[500] }}
                            fontSize="inherit"
                        />
                    </IconButton>
                )}
            {!showButton &&
                action === 'unremove' &&
                record.status === 0 &&
                (record?.schools?.filter(s => s.status !== 0)?.length ?? 0) >
                    0 && (
                    <IconButton
                        key={`unremove_${record.guid}`}
                        size="small"
                        title={
                            props.title ||
                            translate(
                                `resources.${resource}.actions.unremove.button_title`
                            )
                        }
                        onClick={async e => {
                            e.stopPropagation();
                            setSendMail(false);
                            setLanguage('en');
                            setCheckedState({ [school_guid]: true });
                            setShouldTriggerAction(true);
                        }}
                    >
                        <AddCircleOutlineIcon
                            sx={{ color: green[500] }}
                            fontSize="inherit"
                        />
                    </IconButton>
                )}
            {action === 'approve' && (
                <Dialog
                    onClick={e => e.stopPropagation()}
                    open={open}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'lg'}
                    sx={{ '& .MuiDialog-paper': { padding: 2 } }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ backgroundColor: 'primary.main', color: 'white' }}
                    >
                        {translate(
                            `resources.${resource}.actions.${action}.dialog.title`,
                            {
                                mode:
                                    record.status === 2
                                        ? 'tunnus'
                                        : 'koululiitos',
                            }
                        )}
                    </DialogTitle>

                    <DialogContent>
                        {record.status === 2 && (
                            <DialogContentText
                                id="alert-dialog-description"
                                sx={{ marginTop: 2 }}
                            >
                                {translate(
                                    `resources.${resource}.actions.${action}.dialog.content`,
                                    {
                                        name: `${record?.public.firstname} ${record?.public.lastname}`,
                                        username: `${record?.username}`,
                                    }
                                )}
                            </DialogContentText>
                        )}
                        {(record?.schools?.filter(s => s.status === 2)
                            ?.length ?? 0) > 0 && (
                            <DialogContentText sx={{ marginTop: 2 }}>
                                {translate(
                                    `resources.${resource}.actions.${action}.dialog.schools`
                                )}
                            </DialogContentText>
                        )}
                        {record?.schools?.map(school => (
                            <DialogContentText key={school.guid}>
                                {school.status === 2 && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    checkedState[school.guid]
                                                }
                                                onChange={handleCheckboxChange}
                                                name={school.guid}
                                                color="primary"
                                            />
                                        }
                                        label={school.name}
                                        key={school.guid}
                                    />
                                )}
                            </DialogContentText>
                        ))}
                    </DialogContent>

                    <DialogActions>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={sendmail}
                                    onChange={handleSendMailCheckboxChange}
                                    name="sendmail"
                                    color="primary"
                                />
                            }
                            label={translate(
                                `resources.${resource}.actions.${action}.dialog.sendmail`
                            )}
                        />

                        <FormControl sx={{ width: 120 }}>
                            <InputLabel id="language-select-label">
                                {translate(
                                    `resources.${resource}.actions.${action}.dialog.language`
                                )}
                            </InputLabel>
                            <Select
                                labelId="language-select-label"
                                id="language-select"
                                disabled={!sendmail}
                                value={language}
                                label={translate(
                                    `resources.${resource}.actions.${action}.dialog.language`
                                )}
                                onChange={handleLanguageChange}
                            >
                                <MenuItem value="fi">Suomi</MenuItem>
                                <MenuItem value="sv">Ruotsi</MenuItem>
                                <MenuItem value="en">Englanti</MenuItem>
                            </Select>
                        </FormControl>

                        <Button
                            onClick={handleCloseDialog}
                            color="primary"
                            variant="outlined"
                            autoFocus
                        >
                            {translate(
                                `resources.${resource}.actions.${action}.dialog.cancel`
                            )}
                        </Button>
                        <Button
                            onClick={handleContinue}
                            color="primary"
                            variant="contained"
                        >
                            {translate(
                                `resources.${resource}.actions.${action}.dialog.confirm`
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {action === 'remove' && (
                <Dialog
                    onClick={e => e.stopPropagation()}
                    open={open}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'lg'}
                    sx={{ '& .MuiDialog-paper': { padding: 2 } }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            backgroundColor: 'error.main',
                            color: 'white',
                        }}
                    >
                        {translate(
                            `resources.${resource}.actions.${action}.dialog.title`,
                            {
                                mode:
                                    (record?.schools?.length ?? 0) > 0
                                        ? 'koululiitoksen'
                                        : 'tunnuksen',
                            }
                        )}
                    </DialogTitle>

                    <DialogContent>
                        {(record?.schools?.filter(s => s.status !== 1)
                            ?.length ?? 0) > 0 && (
                            <DialogContentText sx={{ marginTop: 2 }}>
                                {translate(
                                    `resources.${resource}.actions.${action}.dialog.schools`
                                )}
                            </DialogContentText>
                        )}
                        {record?.schools?.map(school => (
                            <DialogContentText key={school.guid}>
                                {school.status !== 1 && (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={
                                                    checkedState[school.guid]
                                                }
                                                onChange={handleCheckboxChange}
                                                name={school.guid}
                                                color="primary"
                                            />
                                        }
                                        label={school.name}
                                        key={school.guid}
                                    />
                                )}
                            </DialogContentText>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Stack direction={'column'} spacing={0}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={remove_user}
                                        onChange={
                                            handleRemoveTeacherCheckboxChange
                                        }
                                        name="remove_user"
                                        color="primary"
                                    />
                                }
                                label={translate(
                                    `resources.${resource}.actions.${action}.dialog.remove_user`,
                                    {
                                        name: `${record?.public.firstname} ${record?.public.lastname}`,
                                        username: `${record?.username}`,
                                    }
                                )}
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={remove_groups}
                                        onChange={
                                            handleRemoveGroupsCheckboxChange
                                        }
                                        name="remove_groups"
                                        color="primary"
                                    />
                                }
                                label={translate(
                                    `resources.${resource}.actions.${action}.dialog.remove_groups`
                                )}
                            />
                        </Stack>
                        <Button
                            onClick={handleCloseDialog}
                            color="primary"
                            variant="outlined"
                            autoFocus
                        >
                            {translate(
                                `resources.${resource}.actions.${action}.dialog.cancel`
                            )}
                        </Button>
                        <Button
                            onClick={handleContinue}
                            color="error"
                            variant="contained"
                        >
                            {translate(
                                `resources.${resource}.actions.${action}.dialog.confirm`
                            )}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default ChangeUserStatus;
