import React from 'react';
import {
    FilterButton,
    List,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
    Title,
    useNotify,
    WithListContext,
    DatagridLoading,
} from 'react-admin';
import { useMediaQuery, Theme } from '@mui/material';
import { Alert } from '@mui/material';
import TeacherListAside from './TeacherListAside';
// import ProductFullNameField from '../product/ProductFullNameField';
// import SubscriberFullNameField from './SubscriberFullNameField';
import CustomLogAsTeacherButton from '../../controls/CustomLogAsTeacherButton';
import CustomPagination from '../../common/CustomPagination';
import SourceNameField from '../SourceNameField';
import SessionBadge from '../controls/SessionBadge';
import ConditionalLoginField from '../ConditionalLoginField';
import SchoolNameField from '../SchoolNameField';
import CreateUserDialogButton from '../CreateUserDialogButton';

const teacherFilters = [<SearchInput source="q" alwaysOn />];

const ListActions = () => {
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}

            <CreateUserDialogButton resource="teacher" />
            {/* Specify the resource prop if required */}
            {/* <ExportButton /> */}
            {/* <ListViewButton /> */}
            <SelectColumnsButton />
        </TopToolbar>
    );
};

const TeacherList = () => {
    const notify = useNotify();
    const handleOnClick = e => {
        e.stopPropagation();
        navigator.clipboard.writeText(e.target.innerText);
        //notify(e.target.innerText + ' kopioitu leikepöydälle.', { type: 'info' });
        notify(
            <Alert severity="info">
                {e.target.innerText} kopioitu leikepöydälle.
            </Alert>
        );
    };

    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = [
        'public.firstname',
        'public.lastname',
        'username',
        'last_login',
        'registered',
    ];

    const translate = useTranslate();
    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    return (
        <List
            filters={isSmall ? teacherFilters : undefined}
            sort={{ field: 'registered', order: 'DESC' }}
            perPage={25}
            aside={<TeacherListAside />}
            actions={<ListActions />}
            filter={{ need_count: true, sessions: true, licenses: false }}
            title={<Title title="My Custom Page" />}
            pagination={false ? <CustomPagination /> : undefined}
        >
            <WithListContext
                render={({ isLoading, isFetching, data }) =>
                    isLoading || isFetching ? (
                        <DatagridLoading nbChildren={8} nbFakeLines={8} />
                    ) : (
                        <DatagridConfigurable
                            /*
                            rowSx={record => ({
                                color:
                                    record.status === 0
                                        ? 'none'
                                        : 'rgba(255, 0, 0, 0.05)',
                            })}
                            */
                            //optimized
                            omit={['guid']}
                            rowClick="edit"
                            //rowClick={(id: any, basePath: any, record: any) => {window.open("https://oppimateriaalit-aspa.otava.fi/#/"+basePath+"/"+id, "_blank");return false;}}
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                        >
                            {/* {columns} */}
                            <TextField
                                source="guid"
                                key="guid"
                                sortable={sortable.includes('guid')}
                            />
                            <TextField
                                source="public.firstname"
                                key="firstname"
                                sortable={sortable.includes('public.firstname')}
                                onClick={handleOnClick}
                            />
                            <TextField
                                source="public.lastname"
                                key="lastname"
                                sortable={sortable.includes('public.lastname')}
                                onClick={handleOnClick}
                            />
                            <TextField
                                source="username"
                                key="username"
                                sortable={sortable.includes('username')}
                                onClick={handleOnClick}
                            />
                            <SchoolNameField
                                source="school.title"
                                key="school"
                                approval="true"
                                sortable={sortable.includes('school')}
                            />

                            <SessionBadge
                                source="last_login"
                                options={{
                                    showTTL: false,
                                }}
                                sortByOrder="DESC"
                            >
                                <ConditionalLoginField
                                    showTime
                                    locales="fi-FI"
                                    sortable={sortable?.includes('last_login')} // Conditional sortable
                                    sortByOrder="DESC"
                                    allConditions="false"
                                />
                            </SessionBadge>

                            <FunctionField
                                source="registered"
                                key="registered"
                                sortable={sortable.includes('registered')}
                                sortByOrder="DESC"
                                render={record =>
                                    record.status === 0 ? (
                                        new Date(
                                            record.registered
                                        ).toLocaleDateString('fi-FI')
                                    ) : (
                                        <span
                                            title={`${new Date(
                                                record.registered
                                            ).toLocaleDateString('fi-FI')}`}
                                        >
                                            {translate(
                                                `resources.user.status.${record.status}`
                                            )}
                                        </span>
                                    )
                                }
                            />
                            <CustomLogAsTeacherButton /* sortable={sortable.includes('log_as_teacher')} */
                            />
                            <SourceNameField label="Lähde" size="small" />
                        </DatagridConfigurable>
                    )
                }
            />
        </List>
    );
};

export default TeacherList;
