import * as React from 'react';
import {
    Datagrid,
    Edit,
    NumberInput,
    BooleanInput,
    ReferenceManyField,
    TextInput,
    TabbedForm,
    FormTab,
    SaveButton,
    Toolbar,
    FileInput,
    FileField,
    ReferenceField,
    FunctionField,
    DateField,
    DateTimeInput,
    DateInput,
    Labeled,
    useTranslate,
    BooleanField,
    useEditContext,
    TextField,
    AutocompleteInput,
    ReferenceInput,
    useExpanded,
    useRecordContext,
    RecordContext,
    ArrayInput,
    SimpleFormIterator,
    FormDataConsumer,
} from 'react-admin';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength,
    number,
    email,
    regex,
    choices,
} from 'react-admin';
import { Grid, Box, Typography, Stack, Link } from '@mui/material';

import Merge from '../controls/Merge';

import {
    CustomSelectInput,
    CustomSelectArrayInput,
    CustomCheckboxGroupInput,
    CustomRadioButtonGroupInput,
    CustomJSONPretty,
    CustomTextField,
    CustomBulkDeleteButton,
    CustomBulkAddButton,
    CustomProductList,
    CustomNumberInput,
    CustomLogEventList,
    CustomUserLicenseByUserList,
    CustomUserSchoolList,
    CustomAutocompleteInput,
    CustomUserGroupList,
    CustomAddSubscriptionButton,
} from '../../controls';

import {
    LibraryBooks,
    Article,
    Podcasts,
    Attachment,
    DataObject,
    TransferWithinAStation,
    History,
    Computer,
    Source,
} from '@mui/icons-material';
import { Label } from 'recharts';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import RoleNameField from '../RoleNameField';
import SubjectListField from '../SubjectListField';
import CustomEmpty from '../../controls/CustomEmpty';

import CustomStatistics from '../../controls/CustomStatistics';
import StatsWithIcon from '../../../components/statistics/controls/StatsWithIcon';
import SourceNameField from '../SourceNameField';
import SessionBadge from '../controls/SessionBadge';
import ConditionalLoginField from '../ConditionalLoginField';
import ConditionalMergeField from '../ConditionalMergeField';
import {
    MailExample,
    SendMailApplication,
    SendMailLang,
} from '../CreateUserDialogButton';
import PasswordResetLink from '../PasswordResetLink';

const getLastSegment = (url: string): string => {
    const segments = url.split('/');
    return segments.pop() || '';
};

export const ValidateEmailUnicity = async (
    value: string,
    mustExist: boolean = false,
    userId?: string
) => {
    userId = userId ?? getLastSegment(window.location.href);
    console.log('userId', userId);
    console.log('ValidateEmailUnicity', value);
    console.log('value', value);
    console.log('mustExist', mustExist);

    if (email()(value) !== undefined) {
        return email()(value);
    }

    let api = `${
        process.env.REACT_APP_INFRA_API
    }/teacher?filter=${encodeURIComponent(
        JSON.stringify({ q: value, need_count: false, not_id: userId })
    )}`;

    const response = await fetch(api, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
    });

    const users = await response.json();
    const isEmailUnique = users.length === 0;

    if (!isEmailUnique && !mustExist) {
        // You can return a translation key as well
        return 'notifications.EMAIL_ALREADY_USED';

        // Or even an object just like the other validators

        /*
        return {
            message: 'myroot.validation.email_already_used',
            args: { email: value },
        };
        */
    } else if (isEmailUnique && mustExist) {
        return 'notifications.EMAIL_NOT_EXISTS';
    }
    return undefined;
};

const EditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

const ApplicationForm = () => {
    const useEdit = useEditContext();
    //console.log('record', record);
    return (
        <RowForm defaultValues={{ user_guid: useEdit.record.guid }}>
            <CustomSelectInput
                resource="userapplication"
                source="application_guid"
                url="extralist/userapplication/applications"
                label=""
                validate={[required()]}
            />
            <BooleanInput
                resource="userapplication"
                source="isadmin"
                label=""
            />
            <CustomSelectInput
                resource="userapplication"
                source="public.userrights"
                url="extralist/userapplication/userrights"
                label=""
                showIfControl="application_guid"
                showIfValue="13b312b5-367c-4c74-bb77-bdb34df4e721"
                validate={[required()]}
            />
            <CustomSelectInput
                resource="userapplication"
                source="status"
                url="extralist/userapplication/statuses"
                label=""
                validate={[required()]}
            />
        </RowForm>
    );
};

const SchoolFormEdit = () => {
    const useEdit = useEditContext();
    const translate = useTranslate();
    //console.log('record', record);
    return (
        <RowForm
            defaultValues={{
                user_guid: useEdit.record.guid,
                group_guid: useEdit.record.group_guid,
            }}
        >
            {/* <CustomAutocompleteInput resource="usergroup" source="group_guid" url="extralist/schools" /> */}
            <TextField resource="usergroup" source="group.name" label="" />
            <CustomSelectInput
                resource="usergroup"
                source="role_guid"
                label=""
                url="extralist/teacher/roles"
                validate={required()}
            />
            <FormDataConsumer>
                {({ formData, scopedFormData }) => {
                    return formData && formData?.role_guid ? (
                        <CustomSelectArrayInput
                            label="resources.teacher.fields.role.subjects"
                            resource="teacher"
                            source={`public.subject`}
                            optionText="name"
                            optionValue="guid"
                            url={`extralist/teacher/role/${formData?.role_guid}/subjects`}
                        />
                    ) : null;
                }}
            </FormDataConsumer>
            <BooleanInput
                resource="usergroup"
                source="public.ofduty"
                label=""
            />
            <BooleanInput
                resource="usergroup"
                source="public.isadmin"
                label=""
            />
            <CustomSelectInput
                resource="usergroup"
                source="status"
                label=""
                url="extralist/teacher/statuses"
                validate={required()}
            />
        </RowForm>
    );
};

const SchoolFormCreate = () => {
    const useEdit = useEditContext();
    //console.log('record', record);
    return (
        <RowForm defaultValues={{ user_guid: useEdit.record.guid }}>
            {/* <CustomAutocompleteInput resource="usergroup" source="group_guid" url="extralist/schools" /> */}
            <ReferenceInput
                resource="usergroup"
                source="group_guid"
                reference="extralist/schools"
            >
                <AutocompleteInput
                    source="group_guid"
                    optionText="item_title"
                    optionValue="item_id"
                    label="Koulu"
                    validate={required()}
                />
            </ReferenceInput>
            <CustomSelectInput
                resource="usergroup"
                source="role_guid"
                label="Rooli"
                url="extralist/teacher/roles"
                validate={required()}
            />
            <FormDataConsumer>
                {({ formData, scopedFormData }) => {
                    return formData && formData?.role_guid ? (
                        <CustomSelectArrayInput
                            label="resources.teacher.fields.role.subjects"
                            resource="teacher"
                            source={`public.subject`}
                            optionText="name"
                            optionValue="guid"
                            url={`extralist/teacher/role/${formData?.role_guid}/subjects`}
                        />
                    ) : null;
                }}
            </FormDataConsumer>
            <BooleanInput
                resource="usergroup"
                source="public.ofduty"
                label=""
            />
            <BooleanInput
                resource="usergroup"
                source="public.isadmin"
                label=""
            />
            <CustomSelectInput
                resource="usergroup"
                source="status"
                label="Tila"
                url="extralist/teacher/statuses"
                validate={required()}
            />
        </RowForm>
    );
};

const ClassList = () => {
    const useRecord = useRecordContext();
    //console.log('useEditSchool', useRecord);
    return (
        <Edit
            resource="group"
            id={useRecord.group_guid}
            title=" "
            className="cgl-dg-edit"
        >
            <Grid container width={{ xs: '100%' }} spacing={2}>
                <Grid item xs={12} md={12}>
                    <CustomUserGroupList
                        disableSyncWithLocation
                        storeKey="customUserGroupList"
                        resource="usergroup"
                        params={{ user_guid: useRecord.user_guid }}
                        filter={{ type: 4 }}
                        customFilters={['parent_guid', 'user_guid']}
                        showExtra={['onlyactive']} //['onlyactive']
                        hideFields={[]} //['join', 'status', 'group_status', 'name', 'archived']
                        bulkActionButtons={false}
                    >
                        <span />
                    </CustomUserGroupList>
                </Grid>
            </Grid>
        </Edit>
    );
};

const TeacherEdit = () => {
    const translate = useTranslate();
    return (
        <Edit redirect={false} mutationMode="optimistic">
            <TabbedForm toolbar={<EditToolbar />}>
                <FormTab
                    label="resources.teacher.tabs.profile"
                    icon={<Article />}
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={8}>
                            <TextInput
                                resource="teacher"
                                source="public.firstname"
                                validate={[required()]}
                                fullWidth
                            />
                            <TextInput
                                resource="teacher"
                                source="public.lastname"
                                validate={[required()]}
                                fullWidth
                            />
                            <TextInput
                                resource="teacher"
                                source="username"
                                fullWidth
                                validate={value => {
                                    return ValidateEmailUnicity(value);
                                }}
                            />

                            <TextInput
                                resource="teacher"
                                source="public.description"
                                fullWidth
                            />
                            <CustomSelectInput
                                resource="teacher"
                                source="status"
                                url="extralist/teacher/statuses"
                                parse={v => parseInt(v)}
                                fullWidth
                            />

                            <PasswordResetLink />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <Typography variant="subtitle1">
                                {translate(
                                    'resources.teacher.fields.group.valid'
                                )}
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    color: 'text.secondary',
                                }}
                            >
                                {translate(
                                    'resources.teacher.fields.group.valid_desc'
                                )}
                            </Typography>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <DateInput
                                    resource="teacher"
                                    source="public.validfrom"
                                />
                            </Box>
                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <DateInput
                                    resource="teacher"
                                    source="public.validto"
                                />
                            </Box>

                            <BooleanInput
                                resource="teacher"
                                source="public.login_not_allowed"
                            />
                            <Stack direction="column">
                                <Stack direction="column">
                                    <BooleanInput
                                        resource="teacher"
                                        source="public.profile_locked"
                                    />
                                </Stack>
                                <Stack direction="row">
                                    <FormDataConsumer>
                                        {({ formData, scopedFormData }) => {
                                            return formData &&
                                                formData?.public
                                                    ?.profile_locked ? (
                                                <Stack direction="column">
                                                    <Stack
                                                        direction="row"
                                                        spacing={2}
                                                    >
                                                        <BooleanInput
                                                            resource="teacher"
                                                            label="resources.user.fields.public.profile_locked_message"
                                                            source="notification.profile_locked"
                                                        />

                                                        <FormDataConsumer>
                                                            {({
                                                                formData,
                                                                ...rest
                                                            }) =>
                                                                formData
                                                                    ?.notification
                                                                    ?.profile_locked ? (
                                                                    <>
                                                                        <SendMailLang source="notification.lang" />
                                                                        <SendMailApplication
                                                                            resource="teacher"
                                                                            source="notification.application"
                                                                            {...rest}
                                                                        />
                                                                    </>
                                                                ) : null
                                                            }
                                                        </FormDataConsumer>
                                                    </Stack>
                                                    <Stack direction="column">
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: 'text.secondary',
                                                            }}
                                                        >
                                                            {translate(
                                                                'resources.user.fields.public.profile_locked_description'
                                                            )}
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            ) : null;
                                        }}
                                    </FormDataConsumer>
                                </Stack>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) =>
                                        formData?.notification
                                            ?.profile_locked &&
                                        formData?.notification?.lang &&
                                        formData?.notification?.application ? (
                                            <Stack
                                                direction="column"
                                                sx={{ marginTop: 2 }}
                                            >
                                                <MailExample
                                                    lang={
                                                        formData?.notification
                                                            ?.lang
                                                    }
                                                    application={
                                                        formData?.notification
                                                            ?.application || ''
                                                    }
                                                    status={formData.status}
                                                    mailtemplate="UNLOCK_PROFILE"
                                                    {...rest}
                                                />
                                            </Stack>
                                        ) : null
                                    }
                                </FormDataConsumer>
                            </Stack>
                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: 2, marginTop: 2 }}
                            >
                                <Labeled>
                                    <SessionBadge
                                        source="last_login"
                                        options={{
                                            showTTL: true,
                                            showSessionDelete: true,
                                        }}
                                    >
                                        <ConditionalLoginField
                                            resource="teacher"
                                            showTime
                                            locales="fi-FI"
                                        />
                                    </SessionBadge>
                                </Labeled>
                            </Box>

                            <Box display={{ xs: 'block', sm: 'flex' }}>
                                <ConditionalMergeField showRemove={true} />
                            </Box>

                            <Box
                                display={{ xs: 'block', sm: 'flex' }}
                                sx={{ marginBottom: 2 }}
                            >
                                <Labeled label="resources.teacher.fields.services">
                                    <SourceNameField showRemove={true} />
                                </Labeled>
                            </Box>

                            <Stack direction="row" spacing={4}>
                                <Box>
                                    <Labeled label="resources.teacher.fields.author.creator">
                                        <>
                                            <ReferenceField
                                                reference="user"
                                                source="author.creator"
                                            >
                                                <FunctionField
                                                    render={record =>
                                                        `${record.public.firstname} ${record.public.lastname}`
                                                    }
                                                />
                                            </ReferenceField>
                                            <DateField
                                                resource="teacher"
                                                source="registered"
                                                showTime
                                                locales={'fi'}
                                            />
                                        </>
                                    </Labeled>
                                </Box>
                                <Box>
                                    <Labeled label="resources.teacher.fields.author.modifier">
                                        <>
                                            <ReferenceField
                                                reference="user"
                                                source="author.modifier"
                                            >
                                                <FunctionField
                                                    render={record =>
                                                        `${record.public.firstname} ${record.public.lastname}`
                                                    }
                                                />
                                            </ReferenceField>
                                            <DateField
                                                resource="teacher"
                                                source="author.modified"
                                                showTime
                                                locales={'fi'}
                                            />
                                        </>
                                    </Labeled>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.user.tabs.application"
                    icon={<Computer />}
                    path="palvelut"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <ReferenceManyField
                                reference="userapplication"
                                target="user_guid"
                            >
                                <EditableDatagrid
                                    bulkActionButtons={false}
                                    createForm={<ApplicationForm />}
                                    editForm={<ApplicationForm />}
                                >
                                    <CustomSelectInput
                                        resource="userapplication"
                                        source="application_guid"
                                        label={translate(
                                            'resources.user.fields.application.name'
                                        )}
                                        url="extralist/userapplication/applications"
                                        showAsText={true}
                                    />
                                    <BooleanField
                                        resource="userapplication"
                                        source="isadmin"
                                        label={translate(
                                            'resources.user.fields.application.isadmin'
                                        )}
                                    />
                                    <CustomSelectInput
                                        resource="userapplication"
                                        source="public.userrights"
                                        label={translate(
                                            'resources.user.fields.application.role'
                                        )}
                                        url="extralist/userapplication/userrights"
                                        showAsText={true}
                                    />
                                    <CustomSelectInput
                                        resource="userapplication"
                                        source="status"
                                        label={translate(
                                            'resources.user.fields.application.status'
                                        )}
                                        url="extralist/userapplication/statuses"
                                        showAsText={true}
                                    />
                                </EditableDatagrid>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.teacher.tabs.schools_and_groups"
                    icon={<LibraryBooks />}
                    path="koulut_ja_ryhmat"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <ReferenceManyField
                                reference="usergroup"
                                target="user_guid"
                                filter={{ type: 2 }}
                            >
                                <EditableDatagrid
                                    bulkActionButtons={false}
                                    createForm={<SchoolFormCreate />}
                                    editForm={<SchoolFormEdit />}
                                    expand={<ClassList />}
                                    expandSingle={true}
                                    rowClick="expand"
                                    noDelete
                                >
                                    {/* <CustomSelectInput resource="usergroup" source="group_guid" label={translate('resources.user.fields.group.name')} url="extralist/schools" filter="group_guid" showAsText={true} /> */}
                                    <FunctionField
                                        resource="usergroup"
                                        source="group.name"
                                        label={translate(
                                            'resources.user.fields.group.name'
                                        )}
                                        render={record => {
                                            return (
                                                <Link
                                                    href={`#/school/${record.group_guid}`}
                                                    sx={{ color: 'inherit' }}
                                                    onClick={e =>
                                                        e.stopPropagation()
                                                    }
                                                    underline="hover"
                                                >
                                                    {record.group.name}
                                                </Link>
                                            );
                                        }}
                                    />
                                    <CustomSelectInput
                                        resource="usergroup"
                                        source="role_guid"
                                        label={translate(
                                            'resources.user.fields.group.role'
                                        )}
                                        url="extralist/teacher/roles"
                                        showAsText={true}
                                    />

                                    <SubjectListField
                                        label={translate(
                                            'resources.userlist.fields.subjects'
                                        )}
                                    />

                                    <BooleanField
                                        resource="usergroup"
                                        source="public.ofduty"
                                        label={translate(
                                            'resources.user.fields.group.ofduty'
                                        )}
                                        defaultValue="false"
                                    />
                                    <BooleanField
                                        resource="usergroup"
                                        source="public.isadmin"
                                        label={translate(
                                            'resources.user.fields.group.isadmin'
                                        )}
                                    />
                                    <CustomSelectInput
                                        resource="usergroup"
                                        source="status"
                                        label={translate(
                                            'resources.user.fields.group.status'
                                        )}
                                        url="extralist/teacher/statuses"
                                        showAsText={true}
                                    />

                                    {/* <CustomSelectInput resource="usergroup" source="public.userrights" label={translate('resources.user.fields.group.role')} url="extralist/userapplication/userrights" showAsText={true} />
                                     */}
                                </EditableDatagrid>
                            </ReferenceManyField>
                        </Grid>
                    </Grid>
                    {/* <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomUserSchoolList disableSyncWithLocation storeKey='userSchoolList' resource='usergroup' filter={{type:2}} customFilters={['user_guid']} showExtra={['']} hideFields={['']} bulkActionButtons={false}><span /></CustomUserSchoolList>
                        </Grid>
                    </Grid> */}
                </FormTab>
                <FormTab
                    label="resources.teacher.tabs.materials"
                    icon={<LibraryBooks />}
                    path="kaytossa"
                >
                    
                    <Grid container width={{ xs: '100%' }} spacing={2}>

                        <Grid item xs={12} md={12}>
                            <Typography variant="h5" paragraph>
                                Lisää tilaus
                            </Typography>
                            <CustomProductList
                                resource="product"
                                disableSyncWithLocation
                                customFilters={[
                                    'use_query_min_length',
                                    'use_v2',
                                ]}
                                showExtra={['search', 'info']}
                                sort={{ field: 'name', order: 'ASC' }}
                                bulkActionButtons={
                                    <CustomAddSubscriptionButton />
                                }
                            >
                                <span />
                            </CustomProductList>
                        </Grid>
                        
                        <Grid item xs={12} md={12}>
                            <CustomUserLicenseByUserList
                                resource="userlicense"
                                disableSyncWithLocation
                                customFilters={['user_guid']}
                                showExtra={[]}
                                hideFields={[]}
                                sort={{ field: 'product.name', order: 'ASC' }}
                                bulkActionButtons={<CustomBulkDeleteButton />}
                            >
                                <span />
                            </CustomUserLicenseByUserList>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.teacher.tabs.merge"
                    icon={<LibraryBooks />}
                    path="yhdistaminen"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <Merge />
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab
                    label="resources.teacher.tabs.logs"
                    icon={<History />}
                    path="logit"
                >
                    <Grid container width={{ xs: '100%' }} spacing={2}>
                        <Grid item xs={12} md={12}>
                            <CustomStatistics
                                statisticKey="userEvents"
                                handler={StatsWithIcon}
                                mode="List"
                                from="30d"
                                limit={30}
                                exclude_search={[
                                    'updateAspaProfile',
                                    'FORCE_LOGOUT',
                                ]}
                                include_search={[]}
                                pagination={true}
                                showFilter={true}
                            />
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};

const redirect: any = () => {
    return window.location.hash.replace('#', '');
};

export default TeacherEdit;
