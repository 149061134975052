import * as React from 'react';
import { FC, useState, useEffect } from 'react';
//import { useFormState } from 'react-final-form';
import { useWatch } from 'react-hook-form';
import {
    useDataProvider,
    Loading,
    SelectInput,
    useRecordContext,
    FunctionField,
} from 'react-admin';
import { useQuery } from '@tanstack/react-query';

interface Props {
    source: string;
    resource?: string;
    optionValue?: string;
    optionText?: string;
    allowEmpty?: boolean;
    url: string;
    // qparamkey?: string;
    // qparamval?: string;
    filter?: string;
    fullWidth?: boolean;
    alwaysOn?: boolean;
    showAsText?: boolean;
    showIfControl?: string;
    showIfValue?: any;
    label?: string;
    parse?: any;
    validate?: any;
}

interface ResItem {
    item_id: any;
    item_title: any;
}

const CustomSelectInput: FC<Props> = ({
    source,
    resource = '',
    optionValue = 'item_id',
    optionText = 'item_title',
    allowEmpty = true,
    url,
    // qparamkey,
    // qparamval = '',
    filter = '',
    fullWidth = true,
    alwaysOn = true,
    showAsText = false,
    showIfControl = '',
    showIfValue = '',
    ...props
}) => {
    const record = useRecordContext();
    //console.log('record', record);
    const showCheck = useWatch({ name: showIfControl });
    const showInput =
        showIfControl === ''
            ? true
            : showCheck !== undefined && showCheck === showIfValue
            ? true
            : false;

    const params =
        filter !== ''
            ? 'filter={"' + filter + '": "' + record[filter] + '"}'
            : '';

    const dataProvider = useDataProvider();

    const { data, error, isPending, refetch } = useQuery({
        queryKey: [url, 'getAny', params],
        queryFn: () => dataProvider.getAny(url, params),
    });

    if (!showInput) return null;
    if (!data) return null;
    if (isPending) return <Loading />;

    const customChoices = data.data.filter(
        (item: any) => item.readonly !== true
    );
    // useEffect(() => {
    //     dataProvider
    //         .getAny(url, params)
    //         .then(({ data }) => {
    //             setCustomChoices(data);
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             console.log(error);
    //             setLoading(false);
    //         });
    // }, [params]);

    // if (loading) return <Loading />;
    if (!customChoices) return null;
    const getNestedValue = (obj: any, path: string) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    return showAsText ? (
        <FunctionField
            render={record => {
                const value = getNestedValue(record, source);
                return (
                    value !== undefined &&
                    customChoices.find(
                        (cc: ResItem) =>
                            cc[optionValue].toString() === value.toString()
                    )?.[optionText]
                );
            }}
        />
    ) : (
        <SelectInput
            source={source}
            resource={resource}
            optionValue={optionValue}
            optionText={optionText}
            choices={customChoices}
            //allowEmpty={allowEmpty} tää pitää selvittää
            fullWidth={fullWidth}
            alwaysOn={alwaysOn}
            validate={props.validate ? props.validate : null}
            {...props}
        />
    );
};

export default CustomSelectInput;
