import * as React from 'react';
import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOnOutlined';
import MailIcon from '@mui/icons-material/MailOutline';
import LocalOfferIcon from '@mui/icons-material/LocalOfferOutlined';
import {
    FilterList,
    FilterListItem,
    FilterLiveSearch,
    SavedQueriesList,
    minLength,
} from 'react-admin';
//import { SavedQueriesList } from '@react-admin/ra-preferences';
import {
    endOfYesterday,
    startOfWeek,
    subWeeks,
    startOfMonth,
    subMonths,
} from 'date-fns';

import SubscriberIcon from '@mui/icons-material/Domain';
import ProductIcon from '@mui/icons-material/LibraryBooks';
import StatusIcon from '@mui/icons-material/CheckCircle';

import CustomFilterList from '../controls/CustomFilterList';
import CustomFilterSearch from '../controls/CustomFilterSearch';

//import segments from '../segments/data';

const SchoolListAside = () => (
    <Card
        sx={{
            display: {
                xs: 'none',
                md: 'block',
            },
            order: -1,
            flex: '0 0 15em',
            mr: 2,
            mt: 4.5,
            alignSelf: 'flex-start',
        }}
    >
        <CardContent sx={{ pt: 1 }}>
            <FilterLiveSearch
                source="q"
                variant="outlined"
                onInput={e => {
                    e.target['value'].length > 0 && e.target['value'].length < 3
                        ? e.stopPropagation()
                        : console.log(e.target['value'].length);
                }}
            />
            <SavedQueriesList />
            <CustomFilterList
                url="extralist/school/statuses"
                label="resources.school.filters.status"
                source="status"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/school/adminstatuses"
                label="resources.school.filters.adminstatus"
                source="adminstatus"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/school/teacherstatuses"
                label="resources.school.filters.teacherstatus"
                source="teacherstatus"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/school/pupilstatuses"
                label="resources.school.filters.pupilstatus"
                source="pupilstatus"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/school/studentstatuses"
                label="resources.school.filters.studentstatus"
                source="studentstatus"
                icon={<StatusIcon />}
            />
            <CustomFilterList
                url="extralist/school/subscriptionstatuses"
                label="resources.school.filters.subscriptionstatus"
                source="subscriptionstatus"
                icon={<StatusIcon />}
            />
        </CardContent>
    </Card>
);

export default SchoolListAside;
