import React, { useState } from 'react';
import {
    useRecordContext,
    useTranslate,
    useNotify,
    useRefresh,
    useResourceContext,
} from 'react-admin';
import {
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Stack,
} from '@mui/material';
import ResetIcon from '@mui/icons-material/Refresh';
import { red } from '@mui/material/colors';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Typography } from '@mui/material';
import { url } from 'inspector';

const PasswordResetLink = () => {
    const translate = useTranslate();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();
    const resource = useResourceContext();
    const [saving, setSaving] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(null);

    if (!record) return null;

    const handleCopyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(dialogContent?.url);
            notify('Kopioitu leikepöydälle', { type: 'success' });
        } catch (err) {
            notify('Kopiointi epäonnistui', { type: 'error' });
        }
    };

    const handleResetPassword = () => {
        const action = 'RESET_PASSWORD';
        const data = { ...record, action };
        setSaving(true);

        fetch(`${process.env.REACT_APP_INFRA_API}/${resource}/${record.guid}`, {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                school: localStorage.getItem('school'),
                authorization: 'Bearer ' + localStorage.getItem('token'),
            },
            body: JSON.stringify(data),
        }).then(async ret => {
            setSaving(false);
            if (ret.status !== 200) {
                notify(translate('notifications.ACTION_ERROR'), {
                    type: 'error',
                });
            } else {
                const result = await ret.json();
                result.url =
                    resource === 'teacher'
                        ? `https://opepalvelu.otava.fi/reset-password/${result.token}`
                        : `https://opiskelija.otava.fi.fi/reset-password/${result.token}`;
                result.text = translate(
                    'resources.user.actions.reset_password.description',
                    { link: result.url }
                );
                setDialogContent(result);
                setDialogOpen(true);
                //refresh();
            }
        });
    };

    return (
        <>
            <Button
                variant="outlined"
                startIcon={<ResetIcon style={{ color: red[500] }} />}
                disabled={saving}
                onClick={handleResetPassword}
            >
                {translate(
                    'resources.user.actions.reset_password.button_label'
                )}
            </Button>
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.15)',
                        borderRadius: '8px',
                        padding: '8px',
                        maxWidth: '800px',
                        width: '100%',
                    },
                }}
            >
                <DialogTitle>
                    {translate('resources.user.actions.reset_password.title')}
                </DialogTitle>
                <DialogContent>
                    <Stack direction="row" spacing={2}>
                        <Typography
                            variant="body1"
                            sx={{
                                wordBreak: 'break-all',
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {dialogContent?.url}
                        </Typography>
                        <IconButton
                            onClick={handleCopyToClipboard}
                            size="small"
                            title={translate('ra.action.copy')}
                        >
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        color="primary"
                    >
                        {translate('ra.action.close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default PasswordResetLink;
