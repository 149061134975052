import * as React from 'react';
import { useState } from 'react';
import { useRefresh, useEditContext, useListContext, useNotify, useUnselectAll, Confirm, useUpdateMany, useDataProvider, useCreate } from 'react-admin';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const CustomAddSubscriptionButton = () => {

    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [validFrom, setValidFrom] = useState('1.8.2024');
    const [validUntil, setVallidUntil] = useState('31.7.2025');
    const [licenseCount, setLicenseCount] = useState('1');

    //const [addCount, setAddCount] = useState('0');

    const refresh = useRefresh();
    const parent = useEditContext();
    //console.log(parent);
    const [licenseType, setLicenseType] = (parent.resource == "teacher") ? useState('2') : (parent.resource == "student") ? useState('3') : useState('1');

    const list = useListContext();
    const notify = useNotify();
    const url = `subscription`;

    const unselectAll = useUnselectAll(list.resource);
    const dataProvider = useDataProvider();

    const handleClick = () => setOpen(true);
    
    const handleDialogClose = () => {
        refresh();
        unselectAll();
        setOpen(false);
    }

    const handleConfirm = () => {

        list.selectedIds.map((id, ind) => {

            setIsLoading(true);
            dataProvider.create(
                url,
                { data: {
                        subscriber_guid: (parent.resource == "teacher" || parent.resource == "student") ? parent.record.guid : parent.record.parent_group[0].guid,
                        product_guid: id,
                        license_count: parseInt(licenseCount),
                        valid_from: validFrom,
                        valid_until: validUntil,
                        type: parseInt(licenseType),
                        return_data: true,
                    } 
                }
            ).then(data => {
                //console.log("onnistui");
                notify(`Lisenssi(t) tuotteille lisätty`, { type: 'success' });
            }).catch(error => {
                notify(`Virhe lisättäessä: ${error}`, { type: 'warning' });
            }).finally(() => {
                console.log("finally");
                setIsLoading(false);
                if (list.selectedIds.length <= (ind + 1)) {
                    refresh();
                    unselectAll();
                }
                // setAddCount((parseInt(addCount)-1).toString());
                //console.log(addCount);
            });
        });
        //tää ei toimi, pitää hoitaa tuo refresh vasta, kun kaikki lisätty
        //refresh();
        //unselectAll();
        setOpen(false);
    };
    
    return (
        <>
            <TextField id="license_count" label="Määrä" variant="filled" size="small" value={licenseCount} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setLicenseCount(event.target.value);}}  />
            <TextField id="valid_from" label="Mistä" variant="filled" size="small" value={validFrom} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setValidFrom(event.target.value);}}  />
            <TextField id="valid_until" label="Mihin" variant="filled" size="small" value={validUntil} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {setVallidUntil(event.target.value);}} />
            <Select id="license_type" value={licenseType} label="Lisenssityyppi" variant="filled" size="small" onChange={(event: SelectChangeEvent) => {setLicenseType(event.target.value as string);}}>
                {parent.resource == "school" && <MenuItem value={0}>Oppilaslisenssi</MenuItem>}
                {parent.resource == "school" && <MenuItem value={1}>Koululisenssi</MenuItem>}
                {parent.resource == "teacher" && <MenuItem value={2}>Koekäyttö</MenuItem>}
                {(parent.resource == "teacher" || parent.resource == "student") && <MenuItem value={3}>Oma</MenuItem>}
            </Select>
            <Button onClick={handleClick} disabled={isLoading} color='success'>Lisää</Button>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title="Lisää valitut tilaukset"
                content={"Haluatko varmasti lisätä valitsemasi lisenssit?"}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

export default CustomAddSubscriptionButton;
