import { DateField } from 'react-admin';
import { useRecordContext } from 'react-admin';
import ChangeUserStatus from './ChangeUserStatus';

const ConditionalLoginField = ({ ...rest }) => {
    const record = useRecordContext();
    const source =
        !record?.last_login && Number(record?.status) < 2
            ? 'author.created'
            : 'last_login'; // Optional chaining for safety

    return (
        <>
            {(record.status === 2 ||
                (rest.allConditions &&
                    record?.schools?.filter(s => s.status === 2)?.length)) ??
            0 ? (
                <ChangeUserStatus {...rest} />
            ) : (
                <DateField
                    source={source}
                    key={source} // Key based on source
                    {...rest}
                />
            )}
        </>
    );
};

export default ConditionalLoginField;
