import * as React from 'react';
import { useRecordContext } from 'react-admin';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import CustomStatusField from './CustomStatusField';
import {
    CustomDBDeleteButton,
} from '../controls';

interface Props {
    source: string;
    label?: string;
    sortable?: boolean;
}

const convertDateToString = (value: Date) => {
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    const pad = '00';
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    return `${(pad + dd).slice(-2)}.${(pad + MM).slice(-2)}.${yyyy}`;
};

const convertDateToFullString = (value: Date) => {
    if (!(value instanceof Date) || isNaN(value.getDate())) return '';
    const pad = '00';
    const yyyy = value.getFullYear().toString();
    const MM = (value.getMonth() + 1).toString();
    const dd = value.getDate().toString();
    const hh = value.getHours().toString();
    const min = value.getMinutes().toString();
    return `${(pad + dd).slice(-2)}.${(pad + MM).slice(-2)}.${yyyy} klo. ${(pad + hh).slice(-2)}:${(pad + min).slice(-2)}`;
};

const CustomUserLicenseField = ({
    source = "licenses",
    label = "",
    sortable = false
}: Props) => {
   
    const record = useRecordContext();
    //console.log(record);
    const lastIndex = record[source].length-1;
    // record[props.source].map(l => {
    //     console.log(l);
    // });
    

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 450 }} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell>Luotu</TableCell>
                    <TableCell align="right">Mistä</TableCell>
                    <TableCell align="right">Mihin</TableCell>
                    <TableCell align="right">Tila</TableCell>
                    <TableCell align="right">Tyyppi</TableCell>
                    <TableCell align="right">Nro.</TableCell>
                    <TableCell align="right"></TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {record[source].map((row) => (
                    <TableRow
                    key={row.guid}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        <Tooltip title={convertDateToFullString(new Date(row.created))}><span>{convertDateToString(new Date(row.created))}</span></Tooltip>
                    </TableCell>
                    <TableCell align="right">
                        <Tooltip title={convertDateToFullString(new Date(row.valid_from))}><span>{convertDateToString(new Date(row.valid_from))}</span></Tooltip>
                    </TableCell>
                    <TableCell align="right">
                        <Tooltip title={convertDateToFullString(new Date(row.valid_until))}><span>{convertDateToString(new Date(row.valid_until))}</span></Tooltip>
                    </TableCell>
                    <TableCell align="right">
                        <Tooltip title={`Lisenssin tila. Punainen = poistettu, Vihreä = Käytössä`}><CustomStatusField url="extralist/userlicense/statuses" value={row.status} /></Tooltip>
                    </TableCell>
                    <TableCell align="right">
                        <Tooltip title={`Lisenssin tyyppi.`}><CustomStatusField url="extralist/subscription/types" value={row.type} useIcon={false} /></Tooltip>
                    </TableCell>
                    <TableCell align="right">{row.reduce && <Tooltip title={`Lisenssi on annettu lisenssipoolista järjestysnumerolla ${row.license_order_nro}`}><span>{row.license_order_nro}</span></Tooltip>}</TableCell>
                    <TableCell align="right"><Tooltip title={`Tämä toiminto poistaa lisenssin pysyvästi tietokannasta. Käytä vain, jos tiedät mitä teet!`}><span>{(row.order_index==lastIndex && row.status==1)?<CustomDBDeleteButton resource_guid={row.guid} />:''}</span></Tooltip></TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
    );
};

export default CustomUserLicenseField;
