import SchoolIcon from '@mui/icons-material/School';
import TeacherList from './TeacherList';
import TeacherEdit from './TeacherEdit';
//import TeacherCreate from './TeacherCreate';

export default {
    list: TeacherList,
    //create: TeacherCreate,
    edit: TeacherEdit,
    icon: SchoolIcon,
};
