import * as React from 'react';
import {
    CreateButton,
    DateField,
    FilterButton,
    List,
    NumberField,
    SearchInput,
    TopToolbar,
    TextField,
    DatagridConfigurable,
    SelectColumnsButton,
    useTranslate,
    FunctionField,
    useNotify,
    WithListContext,
    DatagridLoading,
    Link,
    ExportButton,
    downloadCSV,
    TranslatableContext,
    useListContext,
} from 'react-admin';
import { useMediaQuery, Theme, Typography } from '@mui/material';
import { Alert } from '@mui/material';

import CustomStatusField from '../../controls/CustomStatusField';
import StudentListAside from './StudentListAside';
import CustomLogAsStudentButton from '../../controls/CustomLogAsStudentButton';
import get from 'lodash/get';
import CustomPagination from '../../common/CustomPagination';
import SessionBadge from '../controls/SessionBadge';
import ConditionalLoginField from '../ConditionalLoginField';
import SchoolNameField from '../SchoolNameField';
import SourceNameField from '../SourceNameField';
import jsonExport from 'jsonexport/dist';

// import ProductFullNameField from '../product/ProductFullNameField';
// import SubscriberFullNameField from './SubscriberFullNameField';

const studentFilters = [<SearchInput source="q" alwaysOn />];

const ListActions = () => {
    const translate = useTranslate();
    const { filterValues } = useListContext();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
    return (
        <TopToolbar>
            {isSmall ? <FilterButton /> : null}

            {/* <CreateButton /> */}
            {filterValues?.parent_group && filterValues?.status == 10 && (
                <ExportButton
                    label={translate(
                        `resources.student.actions.export.${filterValues?.status}`
                    )}
                    disabled={
                        !filterValues?.parent_group ||
                        filterValues?.status != 10
                    }
                    maxResults={1000}
                    meta={{
                        filter: {
                            need_count: false,
                            sessions: false,
                            licenses: true,
                        },
                    }}
                />
            )}
            <SelectColumnsButton />
            {/* <ListViewButton /> */}
        </TopToolbar>
    );
};

const StudentList = () => {
    const notify = useNotify();
    const handleOnClick = e => {
        e.stopPropagation();
        navigator.clipboard.writeText(e.target.innerText);
        //notify(e.target.innerText + ' kopioitu leikepöydälle.', { type: 'info' });
        notify(
            <Alert severity="info">
                {e.target.innerText} kopioitu leikepöydälle.
            </Alert>
        );
    };

    const isXsmall = useMediaQuery<Theme>(theme =>
        theme.breakpoints.down('sm')
    );
    const sortable = [
        'public.firstname',
        'public.lastname',
        'public.email',
        'last_login',
        'registered',
    ];

    const translate = useTranslate();

    const isSmall = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    const exporter = students => {
        const usersForExport = students.map(student => {
            const userForExport = {
                id: student.id,
                Etunimi: student.public.firstname,
                Sukunimi: student.public.lastname,
                Sähköpostiosoite: student.public.email,
                'Kirjauduttu viimeksi': get(student, 'last_login', ''),
                Rekisteröity: student.registered,
                Oppilaitokset: student.schools
                    .map(school => school.name)
                    .join(', '),
                Mpass: student.services?.includes('mpass') ? 'Kyllä' : 'Ei',
                Lisenssit: student.licenses
                    .filter(
                        license =>
                            license.status === 0 &&
                            Date.parse(license.valid_until) > Date.now()
                    )
                    .map(license => license.name)
                    .join(', '),
            };
            return userForExport;
        });
        jsonExport(
            usersForExport,
            {
                headers: [
                    'id',
                    'Etunimi',
                    'Sukunimi',
                    'Sähköpostiosoite',
                    'Mpass',
                    'Oppilaitokset',
                    'Kirjauduttu viimeksi',
                    'Rekisteröity',
                    'Lisenssit',
                ],
                rowDelimiter: ';',
            },
            (err, csv) => {
                if (err) {
                    console.error('Export error:', err);
                    notify(translate('notifications.EXPORT_ERROR'), {
                        type: 'error',
                    });
                    return;
                }
                downloadCSV(csv, 'users'); // download as 'users.csv' file
            }
        );
    };

    return (
        <List
            filters={isSmall ? studentFilters : undefined}
            sort={{ field: 'registered', order: 'DESC' }}
            filter={{ need_count: true, sessions: true, licenses: false }}
            perPage={25}
            aside={<StudentListAside />}
            actions={<ListActions />}
            pagination={false ? <CustomPagination /> : undefined}
            exporter={exporter}
        >
            <WithListContext
                render={({ isLoading, isFetching, data }) =>
                    isLoading || isFetching ? (
                        <DatagridLoading nbChildren={8} nbFakeLines={8} />
                    ) : (
                        <DatagridConfigurable
                            /*
                            rowSx={record => ({
                                color:
                                    record.status === 0
                                        ? 'none'
                                        : 'rgba(255, 0, 0, 0.05)',
                            })}
                            */
                            //optimized
                            omit={['guid']}
                            rowClick="edit"
                            //rowClick={(id: any, basePath: any, record: any) => {window.open("https://oppimateriaalit-aspa.otava.fi/#/"+basePath+"/"+id, "_blank");return false;}}
                            sx={{
                                '& .column-groups': {
                                    md: { display: 'none' },
                                    lg: { display: 'table-cell' },
                                },
                            }}
                        >
                            {/* {columns} */}
                            <TextField
                                source="guid"
                                key="guid"
                                sortable={sortable.includes('guid')}
                            />
                            <TextField
                                source="public.firstname"
                                key="firstname"
                                sortable={sortable.includes('public.firstname')}
                                onClick={handleOnClick}
                            />

                            <TextField
                                source="public.lastname"
                                key="lastname"
                                sortable={sortable.includes('public.lastname')}
                                onClick={handleOnClick}
                            />

                            <TextField
                                source="public.email"
                                key="email"
                                sortable={sortable.includes('public.email')}
                                onClick={handleOnClick}
                            />
                            <TextField
                                source="username"
                                key="username"
                                sortable={sortable.includes('username')}
                            />
                            <SchoolNameField
                                source="school.title"
                                key="school"
                                sortable={sortable.includes('school')}
                            />
                            <SessionBadge
                                source="last_login"
                                sortByOrder="DESC"
                                options={{
                                    showTTL: false,
                                }}
                            >
                                <ConditionalLoginField
                                    showTime
                                    locales="fi-FI"
                                    sortable={sortable?.includes('last_login')} // Conditional sortable
                                    sortByOrder="DESC"
                                />
                            </SessionBadge>
                            <DateField
                                source="registered"
                                key="registered"
                                showTime={false}
                                locales="fi-FI"
                                sortable={sortable.includes('registered')}
                                sortByOrder="DESC"
                            />
                            <CustomLogAsStudentButton /* sortable={sortable.includes('status')} */
                            />
                            <SourceNameField label="Lähde" size="small" />
                        </DatagridConfigurable>
                    )
                }
            />
        </List>
    );
};

export default StudentList;
